import React from 'react';
import './Installer.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import IosShareIcon from '@mui/icons-material/IosShare';
import {isMobile} from "mobile-device-detect";

const EMULATE_APP = false;

function InstallDialog({manual, open, onClose}) {
	if(manual) {
		return (
			<Dialog onClose={onClose} open={open} >
				<DialogTitle>アプリをインストールする</DialogTitle>
				<DialogContent>
					<DialogContentText>
						1. 下部の共有アイコン<IosShareIcon/>をタップ<br/>
						2. メニューをスクロールし「ホーム画面に追加」を選択<br/>
						<img src={process.env.PUBLIC_URL + "/images/add_to_home.jpg"} alt="add to home" width="100%"/><br/>
						3. このブラウザを閉じる<br/>
						4. ホーム画面のアイコンから起動すると本アプリが利用できます。
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} autoFocus>OK</Button>
				</DialogActions>			
			</Dialog>
		);
	} else {
		return (
			<Dialog onClose={onClose} open={open} >
				<DialogTitle>インストールしたアプリから起動する</DialogTitle>
				<DialogContent>
					<DialogContentText>
						ホーム画面に戻り、追加されたアイコンから起動すると本アプリが利用できます。
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} autoFocus>OK</Button>
				</DialogActions>			
			</Dialog>
		);
	}
}


class Installer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dialog: false,
		};
		this.onInstallClicked = this.onInstallClicked.bind(this);
		this.onCancelClicked = this.onCancelClicked.bind(this);
		this.onDialogClosed = this.onDialogClosed.bind(this);
		this.onBeforeInstallPrompt = this.onBeforeInstallPrompt.bind(this);
		this.deferredPrompt = React.createRef();
	}

	componentDidMount() {
		if(isMobile) {
			window.addEventListener("beforeinstallprompt", this.onBeforeInstallPrompt);
		}
	}

	onBeforeInstallPrompt(event) {
		event.preventDefault();
		this.deferredPrompt.current = event;
	}

	onInstallClicked() {
		if(isMobile || EMULATE_APP) {
			if(this.deferredPrompt.current) {
				this.deferredPrompt.current.prompt()
					.then((event) => this.deferredPrompt.current.userChoice)
					.then((choiceResult) => {
						if (choiceResult.outcome === "accepted") {
							console.debug("PWA installation successful on android");
							this.setState({dialog: true});
						} else {
							console.debug("User opted out");
						}
					})
					.catch((err) => {
						console.error("Error occurred in the installing process: ", err);
					});
			} else {
				console.debug("manual install");
				this.setState({dialog: true});
			}
		}
	}

	onCancelClicked() {
		this.props.onCanceled();
	}

	onDialogClosed() {
		this.setState({dialog: false});
	}

	render() {
		return (
			<div className="install-app-buttons">
				<Button variant="contained" color="success" startIcon={<InstallMobileIcon/>}
					onClick={this.onInstallClicked}
				>
					インストールする
				</Button>
				<InstallDialog manual={!this.deferredPrompt.current} open={this.state.dialog} onClose={this.onDialogClosed} />
				<Button variant="outlined" color="success" startIcon={<OpenInBrowserIcon/>} 
					className="button-without-install" onClick={this.onCancelClicked}
				>					
					インストールせずに使用する
				</Button>
			</div>
		);
	}
}


export default Installer;

