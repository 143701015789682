import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'

import './SpotList.css';

import CATS from './Categories.js'

class SpotListTabPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 0,
		};
		this.onSpotClick = this.onSpotClick.bind(this);
	}

	onSpotClick(spot) {
		this.props.onSelected(spot);
	}

	listSpots() {
		return this.props.spots.map((spot) => {
			if(this.props.kinds) {
				if(this.props.kinds.indexOf(spot.kind) === -1) {
					return "";
				}
			} else {
				if(spot.kind) {
					return "";
				}
			}
			return (
				<Card key={spot.id}
					sx={{
						width: '200px',
						heightMax: '220px',
						flexShrink: 0,
						flexGrow: 0,
						margin: '10px',
						marginTop: '20px'
					}}
				>
					<CardActionArea onClick={() => this.onSpotClick(spot)}>
						<CardMedia
							sx={{ height: 120 }}
							image={spot.thumbnail}
							title={spot.title}
						/>
						<CardContent>
							<Typography gutterBottom variant="body1" component="div"
								sx={{
									textAlign: 'center',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap'
								}}
							>
								{spot.title}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			);
		});
	}

	render() {
		const selected = (this.props.value === this.props.index);

		return (
			<ScrollContainer className='panel-spot-list'
				role="tabpanel"
				hidden={!selected}
				id={`spot-list-tabpanel-${this.props.index}`}
				aria-labelledby={`spot-list-tab-${this.props.index}`}
				hideScrollbars={false}
				style={{
					display: selected ? 'flex' : 'none',
					flexDirection: 'row',
					overflowX: 'scroll',
					alignItems: 'center',
					height: '350px absolute',
				}}
			>
				{(this.props.value === this.props.index) ? this.listSpots() : null}
			</ScrollContainer>			
		);
	}
}


function SpotIcon(props) {
	const { category } = props;

	let icon = process.env.PUBLIC_URL + "/images/mappin" + ((category) ? ("_" + category) : "") + ".svg";

	return (
		<img className="spot-list-tab-icon" src={icon} alt={category + " spot"}
			style={{ width: '20px', height: '20px', marginRight: '5px', pointerEvents:'none'}}
		/>
	);
}


class SpotList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			tab: 0
		};
		this.onHeaderClick = this.onHeaderClick.bind(this);
		this.onSelectTab = this.onSelectTab.bind(this);
		this.onSpotSelected = this.onSpotSelected.bind(this);
	}

	onHeaderClick(event) {
		let newState = {};
		const re = /^drawer-tab-(\d)$/;
		const match = event.target.id.match(re);
		if(match) {
			newState.open = true;
			newState.tab = parseInt(match[1]);
		} else {
			newState.open = !this.state.open;
		}
		this.setState(newState);
	}

	onSelectTab(event, value) {
		console.log("on select tab");
		this.setState({ tab: value, open: true});
	}

	tabProps(index) {
		return {
			id: `spot-list-tab-${index}`,
			'aria-controls': `spot-list-tabpanel-${index}`,
		};
	}

	listTabs(cat) {
		let index = 0;
		return cat.map((tab) => {
			return (
				<Tab label={tab.label} icon={<SpotIcon category={tab.icon}/>} iconPosition="start" {...this.tabProps(index++)} key={`spot-list-tab-${index}`} />
			);
		});
	}

	listTabPanels(cat) {
		let index = 0;
		return cat.map((tab) => {
			return (
				<SpotListTabPanel value={this.state.tab} index={index++} spots={this.props.spots} kinds={tab.kind} onSelected={this.onSpotSelected} key={`spot-list-tabpanel-${index}`} />
			);
		});
	}


	onSpotSelected(spot) {
		this.props.onSelected(spot);
	}

	render() {
		let cat = CATS[this.props.category];

		return (
			<div className="spot-list-layer">
				<div className='spot-list'>
					<div className="spot-list-header" style={{
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						backgroundColor: '#fff',
						width: '100%',
						pointerEvents: 'auto',
					}} >
						<Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
							<Tabs value={this.state.tab} onChange={this.onSelectTab} aria-label="spot list" >
								{this.listTabs(cat)}
							</Tabs>
						</Box>
					</div>
					<div className="spot-list-tabpanels" style={{pointerEvents: 'auto'}}>
						{this.listTabPanels(cat)}
					</div>
				</div>
			</div>
		);
	}
}


export default SpotList;
