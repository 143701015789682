import React from 'react';
import './Splash.css';
import {isMobile} from "mobile-device-detect";
import Button from '@mui/material/Button';
import MapIcon from '@mui/icons-material/Map';
import { Typography } from '@mui/material';

import Installer from './Installer';

const EMULATE_APP = false;

class Splash extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timeout: false,
			started: false,
			show: true
		};
		this.onTimeout = this.onTimeout.bind(this);
		this.onStartClicked = this.onStartClicked.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.onTimeout(), 1000);
	}

	static getDerivedStateFromProps(props, state) {
		const show = Splash.isShown(props, state);
		if(state.show !== show) {
			props.onHide();
			return {show : show};
		} else {
			return null;
		}
	}

	onTimeout() {
		let newState = {timeout: true};
		if(!Splash.isShown(this.props, this.state) && this.state.show) {
			newState.show = false;
			this.props.onHide();
		}
		this.setState(newState);
	}

	onStartClicked() {
		let newState = {started: true};
		if(!Splash.isShown(this.props, this.state) && this.state.show) {
			newState.show = false;
			this.props.onHide();
		}
		this.setState(newState);
	}

	static isInstalled() {
		if(window.navigator.standalone === true || window.matchMedia("(display-mode: standalone)").matches) {
			return true;
		}
		return false;
	}

	static isShown(props, state) {
		const install = props.app && !Splash.isInstalled() && !state.started && (isMobile || EMULATE_APP);
		const load = props.load || !state.timeout;
		const show = load || install || (props.app && !state.started);
		return show;
	}

	render() {
		const install = this.props.app && !Splash.isInstalled() && !this.state.started && (isMobile || EMULATE_APP);
		const load = this.props.load || !this.state.timeout;
		if(!this.state.show) {
			return null;
		}

		const starter = this.props.app && this.state.timeout && !this.state.started && !install;

		if(this.props.app) {
			return (
				<div className="splash"
					style={{
						display: "flex",
						backgroundImage: `url(${process.env.PUBLIC_URL + '/images/top.jpg'})`
					}}>
					<img src={process.env.PUBLIC_URL + "/images/logo-gray.png"} className="top-logo" alt="logo" />
					{install ? <Installer onCanceled={this.onStartClicked} /> : ""}
					<div className="loader" style={{visibility: load ? "visible" : "hidden"}} />
					{starter ? <Button size="large" variant="contained" color="success" startIcon={<MapIcon/>} onClick={this.onStartClicked}>はじめる</Button> : ""}
					<Typography variant='caption' sx={{marginTop: '10vh'}}>
						©霧島市竹子地区地域おこし協力隊 2023
					</Typography>					
				</div>
			);
		} else {
			return (
				<div className="splash"
					style={{
						display: "flex",
					}}>
					<div className="loader" />
				</div>
			);
		}
	}
}

export default Splash;
