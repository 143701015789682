import React from 'react';

import './App.css';

import Splash from './Splash.js';
import SpotInfo from './SpotInfo.js';
import Map from './Map.js';
import {isMobile} from "mobile-device-detect";


class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			spot: null,
			info: false,
			wide: App.isWide(),
			progress: true,
			splash : true
		};
		this.onSpotSelected = this.onSpotSelected.bind(this);
		this.onSpotInfoClicked = this.onSpotInfoClicked.bind(this);
		this.onSpotInfoClosed = this.onSpotInfoClosed.bind(this);
		this.onWindowResized = this.onWindowResized.bind(this);
		this.onMapLoaded = this.onMapLoaded.bind(this);
		this.onSplashHide = this.onSplashHide.bind(this);
		this.timer = 0;
		window.addEventListener('resize', this.onWindowResized);
		window.addEventListener('orientationchange', this.onWindowResized);
		this.spotInfo = React.createRef();	
	}

	async componentDidMount() {
		document.body.addEventListener('touchmove', (e) => {
			if (e.touches.length > 1) {
				e.preventDefault();
			}
		}, {passive: false});
	}	

	static isWide() {
		const orientation = (window.screen.orientation || {}).type;
		let landscape = isMobile && orientation !== undefined && orientation.startsWith("landscape-");
		let wide = window.innerWidth > 834;
		return wide || landscape;
	}

	onSpotSelected(spot) {
		this.setState({spot: spot, info: false});
		this.timer = setTimeout(() => {
			this.setState({info: true});
			this.interval = 0;
		}, 5000);
	}

	onSpotInfoClicked() {
		clearTimeout(this.timer);
		this.setState({info : !this.state.info});
	}

	onSpotInfoClosed() {
		this.setState({spot: null, info: false});
	}

	onMapLoaded() {
		this.setState({progress : false});
	}
	
	onWindowResized() {
		const old = this.state.wide;
		const current = App.isWide();
		if(old !== current) {
			this.setState({wide : current});
		}
	}

	onSplashHide() {
		this.setState({splash : false});
	}

	render() {
		const wide = this.state.wide;
		console.log("category:" + this.props.category);
		return (
			<>
				<Splash load={this.state.progress} app={this.props.app} onHide={this.onSplashHide} />
				<div className={"app-main " + (wide ? "app-main-wide " : "app-main-single ") + ((this.state.splash && this.props.app) ? "app-main-splash " : "")}
					style={{
						height: (this.props.app) ? "100vh" : (wide) ? "50vw" : "100vh",
					}}
				>
					<Map onSelected={this.onSpotSelected} wide={wide} onLoadCompleted={this.onMapLoaded} app={this.props.app} category={this.props.category ? this.props.category : "spot"} />
					<SpotInfo spot={this.state.spot}
						onClicked={this.onSpotInfoClicked} onClosed={this.onSpotInfoClosed}
						expand={this.state.info} wide={wide} ref={this.spotInfo} app={this.props.app}
					/>
				</div>
			</>
		);
	}
}


export default App;
