import React from 'react';
import MapIcon from '@mui/icons-material/Map';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import MouseIcon from '@mui/icons-material/Mouse';

import './SpotInfo.css';

const ZBASE = 700;

function PageLink({link, app}) {
	return (
		<a className="spot-flavor-page-link" href={link}
			style={{
				color: 'white',
				float: 'left',
				textDecoration: 'none',
				display: 'flex',
				alignItems: 'center',
				visibility: app ? "hidden" : "visible"
			}}
		>
			<ImportContactsIcon/>
			More...
		</a>
	);
}

function MapLink({lat, lng}) {
	return (
		<a className="spot-flavor-map-link" href={"https://www.google.com/maps/search/?api=1&query=" + lat + "%2C" + lng}
			target="_blank" rel="noreferrer"
			style={{
				color: 'white',
				float: 'right',
				textDecoration: 'none',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<MapIcon/>
			Google Mapで開く
		</a>
	);
}


class SpotInfo extends React.Component {
	constructor(props) {
		super(props);
		this.onClicked = this.onClicked.bind(this);
		this.onClosed = this.onClosed.bind(this);
		this.node = React.createRef();
		this.holder = React.createRef();
	}

	onClicked() {
		this.props.onClicked();
	}

	onClosed() {
		this.props.onClosed();
	}

	scrollTo() {
		if(this.node.current) {
			this.node.current.scrollIntoView({behavior: 'smooth'});
		} else if(this.holder.current) {
			this.holder.current.scrollIntoView({behavior: 'smooth'});
		}
	}

	render() {
		const width = this.props.wide ? "50vw" : "100vw";
		const height = (this.props.wide && !this.props.app) ? "50vw" : "100vh";
		const touchable = 'ontouchstart' in window;

		if(this.props.spot) {
			const spot = this.props.spot;
			return (
				<div 
					style={{backgroundColor: '#EEEEEEEE', 
						position: 'relative',
						display: 'grid',
						width: width,
						height: height,
						zIndex : ZBASE,
					}}
					onClick={this.onClicked}
					ref={this.node}
					className="spot"
				>
					<div className="spot-img-back" style={{width: '100%', height: height, gridArea: '1/2', zIndex: ZBASE}} />
					<div className="spot-img" style={{width: '100%', height: height, gridArea: '1/2', zIndex: ZBASE + 1}}>
						<img src={spot.img} alt={spot.title} />
					</div>
					<div className="spot-title" style={{
						background: 'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)) top/100% 20% no-repeat',
						gridArea: '1/2',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						textAlign: 'center',
						zIndex: ZBASE + 2
					}}>
						<h2 style={{
							color : "white",
							fontSize : '2em',
							paddingTop : '1em'
						}}>{spot.title}</h2>
					</div>
					<div className="spot-flavor" style={{
						gridArea: '1/2',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						height: height,
						visibility: this.props.expand ? "visible" : "hidden",
						zIndex: ZBASE + 4
					}}>
						<div className={"spot-flavor-wrapper " + (this.props.expand ? "flavor-fade-in" : "")}
							style={{
								margin: 20,
								marginBottom: '5em',
								backgroundColor: 'rgba(0, 0, 0, 0.5)',
								color: "white",
								padding: 20,
								overflowY: "scroll"
							}}
						>
							<div className="spot-flavor-text" dangerouslySetInnerHTML={{__html: spot.text}} />
							<PageLink link={spot.link} app={this.props.app} />
							<MapLink lat={spot.location.lat} lng={spot.location.lng} />
						</div>
					</div>
					<div className="spot-close"
							style={{
								gridArea: '1/2',
								zIndex: ZBASE + 5,
								textAlign: 'right',
								padding: 20,
								pointerEvents: 'none'
							}}
					>
						<HighlightOffIcon fontSize="large" onClick={this.onClosed}
							style={{
								color:'white',
								cursor:'pointer',
								filter: 'drop-shadow(2px 2px 5px #000)',
								pointerEvents: 'auto',
							}}
						/>
					</div>
					<div className="spot-title" style={{
						gridArea: '1/2',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-end',
						textAlign: 'center',
						zIndex: ZBASE + 3
					}}>
						<h3 style={{
							color : "white",
							fontSize : '1em',
							paddingBottom : '2em'
						}}>
							{touchable ? <TouchAppIcon/> : <MouseIcon/>}
							{touchable ? "タップ" : "クリック"}
							で紹介文オン/オフ
						</h3>
					</div>
				</div>
			);	
		} else {
			return null;
		}
	}
}

export default SpotInfo;
