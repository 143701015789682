import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import 'react-indiana-drag-scroll/dist/style.css'
import CATS from './Categories.js'


class SpotListTabPanel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 0,
		};
		this.onSpotClick = this.onSpotClick.bind(this);
	}

	onSpotClick(spot) {
		this.props.onSelected(spot);
	}

	listSpots() {
		return this.props.spots.map((spot) => {
			if(this.props.kinds) {
				if(this.props.kinds.indexOf(spot.kind) === -1) {
					return "";
				}
			} else {
				if(spot.kind) {
					return "";
				}
			}
			return (
				<Card key={spot.id}
					sx={{
						width: '250px',
						heightMax: '300px',
						flexShrink: 0,
						flexGrow: 0,
						margin: '10px',
						marginTop: '20px'
					}}
				>
					<CardActionArea onClick={() => this.onSpotClick(spot)}>
						<CardMedia
							sx={{ height: 180 }}
							image={spot.thumbnail}
							title={spot.title}
						/>
						<CardContent>
							<Typography gutterBottom variant="body1" component="div"
								sx={{
									textAlign: 'center',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap'
								}}
							>
								{spot.title}
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>
			);
		});
	}

	render() {
		const selected = (this.props.value === this.props.index);

		return (
			<ScrollContainer className='spot-list-pane-panel'
				role="tabpanel"
				hidden={!selected}
				id={`spot-list-pane-tabpanel-${this.props.index}`}
				aria-labelledby={`spot-list-pane-tab-${this.props.index}`}
				hideScrollbars={false}
				style={{
					display: selected ? 'flex' : 'none',
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'center',
					alignItems: 'flex-start',
					overflowY: 'scroll',
					alignContent: 'flex-start',
					height: "100%"
				}}
			>
				{(this.props.value === this.props.index) ? this.listSpots() : null}
			</ScrollContainer>			
		);
	}
}


function SpotIcon(props) {
	const { category } = props;

	let icon = process.env.PUBLIC_URL + "/images/mappin" + ((category) ? ("_" + category) : "") + ".svg";

	return (
		<img className="drawer-spot-icon" src={icon} alt={category + " spot"}
			style={{ width: '20px', height: '20px', marginRight: '5px'}}
		/>
	);
}


class SpotListPane extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: 0
		};
		this.onSelectTab = this.onSelectTab.bind(this);
		this.onSpotSelected = this.onSpotSelected.bind(this);
	}

	onSelectTab(event, value) {
		console.log("on select tab");
		this.setState({ tab: value, open: true});
	}

	tabProps(index) {
		return {
			id: `spot-list-pane-tab-${index}`,
			'aria-controls': `spot-list-pane-tabpanel-${index}`,
		};
	}

	listTabs(cat) {
		let index = 0;
		return cat.map((tab) => {
			return (
				<Tab label={tab.label} icon={<SpotIcon category={tab.icon}/>} iconPosition="start" {...this.tabProps(index++)} key={`spot-list-pane-tab-${index}`} />
			);
		});
	}

	listTabPanels(cat) {
		let index = 0;
		return cat.map((tab) => {
			return (
				<SpotListTabPanel value={this.state.tab} index={index++} spots={this.props.spots} kinds={tab.kind} onSelected={this.onSpotSelected} key={`spot-list-pane-tabpanel-${index}`} />
			);
		});
	}

	onSpotSelected(spot) {
		this.props.onSelected(spot);
	}

	render() {
		let cat = CATS[this.props.category];

		return (
			<>
				<div className='list'
					style={{
						backgroundColor: '#fff',
						height: !this.props.app ? '50vw' : '100vh',
						overflow: 'hidden',
					}}
				>
					<Box sx={{ borderBottom: 1, borderColor: 'divider', height: '72px'}}>
						<Tabs value={this.state.tab} onChange={this.onSelectTab} aria-label="spot list">
							{this.listTabs(cat)}
						</Tabs>
					</Box>
					<div className="spot-list-pane-tabpanels" style={{height: "calc(" + (!this.props.app ? '50vw' : '100vh') + " - 72px)" }} >
						{this.listTabPanels(cat)}
					</div>
				</div>
			</>
		);
	}
}


export default SpotListPane;
