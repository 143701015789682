import React from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Typography from '@mui/material/Typography';
import PinchIcon from '@mui/icons-material/Pinch';
import HomeIcon from '@mui/icons-material/Home';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SpotList from './SpotList.js';
import SpotListPane from './SpotListPane.js'
import { InView } from 'react-intersection-observer';

import './Map.css';
import CATS from './Categories.js';


const SERVICE_URL = "https://takaze.kagoshima.jp/wp/wp-json/wp/v2/spot?_embed&per_page=100";

const MAX_SCALE = 40;
const DEAD_TAP = 50;


class Map extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			spots: [],
			selected: -1,
			picked: -1,
			remain: -1,
			scale : 1.0,
			x : 0,
			y : 0,
			dragging : false,
			touch : false,
			doubleTouch : false,
			startX : 0,
			startY : 0,
			initX : 0,
			initY : 0,
			startW : 0,
			initScale : 0,
			show: false,
			currentLat : -1,
			currentLng : -1
		};
		this.onClicked = this.onClicked.bind(this);
		this.onMouseOver = this.onMouseOver.bind(this)
		this.onUpScale = this.onUpScale.bind(this);
		this.onDownScale = this.onDownScale.bind(this);
		this.onResetMap = this.onResetMap.bind(this);
		this.onDragStarted = this.onDragStarted.bind(this);
		this.onDragEnded = this.onDragEnded.bind(this);
		this.onDrag = this.onDrag.bind(this);
		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onReload = this.onReload.bind(this);
		this.onSelectedByList = this.onSelectedByList.bind(this);
		this.tapTime = Date.now();
		this.watchId = 0;
		this.onShowStatusChanged = this.onShowStatusChanged.bind(this);
		this.onPositionUpdated = this.onPositionUpdated.bind(this);
	}

	onClicked(spot) {
		console.debug("clicked:" + spot.title);
		if(Date.now() - this.tapTime < DEAD_TAP) {
			console.log("dead tap");
			return;
		}
		this.setState({selected: spot.id, picked: spot.id, remain: spot.id});
		this.props.onSelected(spot);
	}

	onMouseOver(spot) {
		console.debug("hover:" + spot.title);
		if(this.state.picked === spot.id || this.state.selected === spot.id) {
			return;
		}
		this.tapTime = Date.now();
		this.setState({picked: spot.id, selected: -1});
	}

	onSelectedByList(spot) {
		let newState = {picked: spot.id, selected: -1, remain: -1};
		const pos = this.getRelativePosition(spot);

		newState.x = pos.x;
		newState.y = pos.y;
		this.setState(newState);
	}

	onDragStarted(event) {
		if(this.state.touch) {
			return;
		}
		let newState = {
			dragging : true,
			touch : false,
			startX : event.pageX,
			startY : event.pageY,
			initX : this.state.x,
			initY : this.state.y
		};
		this.setState(newState);
	}

	getUpdatedPosition(pageX, pageY) {
		const width = (this.props.wide ? window.innerWidth / 2 : window.innerWidth) * this.state.scale;
		let newState = {
			x : this.state.initX - (pageX - this.state.startX) / width,
			y : this.state.initY - (pageY - this.state.startY) / width
		};
		newState.x = newState.x > 0.5 ? 0.5 :
			newState.x < -0.5 ? -0.5 : newState.x;
		newState.y = newState.y > 0.5 ? 0.5 :
			newState.y < -0.5 ? -0.5 : newState.y;
		return newState;
	}

	onDragEnded(event) {
		if(this.state.touch) {
			return;
		}
		if(this.state.dragging) {
			let newState = this.getUpdatedPosition(event.pageX, event.pageY);
			newState.dragging = false;
			this.setState(newState);
		}
	}

	onDrag(event) {
		if(this.state.dragging && !this.state.touch) {
			this.setState(this.getUpdatedPosition(event.pageX, event.pageY));
		}
	}

	onTouchMove(event) {
		event.preventDefault();
		let touches = event.touches;
		if(touches.length === 2 && this.state.doubleTouch) {
			let w = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) + Math.pow(touches[0].clientY - touches[1].clientY, 2));

			let left = touches[0].pageX < touches[1].pageX ? 0 : 1;
			let newState = this.getUpdatedPosition(touches[left].pageX, touches[left].pageY);
			let scale = this.state.initScale * w / this.state.startW;
			scale = scale < MAX_SCALE ? scale : MAX_SCALE;
			scale = scale > 1.0 ? scale : 1.0;
			if(scale / this.state.initScale > 1.1 || scale / this.state.initScale < 0.9) {
				newState.scale = scale;
			}
			this.setState(newState);
		}
	}

	onTouchStart(event) {
		let newState = {touch: true};
		if(event.touches.length === 2 && !this.state.doubleTouch) {
			let touches = event.touches;
			let w = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) + Math.pow(touches[0].clientY - touches[1].clientY, 2));
			newState.startW = w;
			newState.initScale = this.state.scale;

			let left = touches[0].pageX < touches[1].pageX ? 0 : 1;
			newState.startX = touches[left].pageX;
			newState.startY = touches[left].pageY;
			newState.initX = this.state.x;
			newState.initY = this.state.y;
			newState.doubleTouch = true;
		}
		this.setState(newState);
	}

	onTouchEnd(event) {
		if(event.touches.length === 0) {
			this.setState({touch : false, doubleTouch : false});
		} 
	}

	onUpScale() {
		let scale = this.state.scale * 1.5;
		scale = scale < MAX_SCALE ? scale : MAX_SCALE;
		this.setState({scale : scale});
	}

	onDownScale() {
		let scale = this.state.scale / 1.5;
		scale = scale > 1.0 ? scale : 1.0;
		this.setState({scale : scale});
	}

	onResetMap() {
		this.setState({
			selected: -1,
			picked: -1,
			scale : 1.0,
			x : 0,
			y : 0,
			tutorial : true,
			remain : -1
		});
	}

	onReload() {
		window.location.reload();
	}

	onPositionUpdated(position) {
		console.log("lat: " +  position.coords.latitude + " lng:" + position.coords.latitude);
		this.setState({currentLat : position.coords.latitude, currentLng : position.coords.latitude});
	}
	
	getRelativePosition(spot) {
		let pos = {x: 0, y: 0};
		pos.y = (31.88959290259528 - spot.location.lat) / 0.0546749473333072 - 0.5;
		pos.x = (spot.location.lng - 130.61763290061012) / 0.0647793694086545 - 0.5;
		return pos;
	}

	async componentDidMount() {
		let newState = {};

		if(this.state.spots.length === 0) {
			let response = await fetch(SERVICE_URL, {
				method: "GET",
				headers: {'Content-type': 'application/json'},
				mode: 'cors',
				cache: 'no-cache'
			});
			if(response.status !== 200) {
				console.error("failed to fetch spots list:" + response.statusText);
				throw new Error("failed to fetch spots list");
			} else {
				let body = await response.json();
				newState.spots = body.map((item) => {
					return {
						id: item.id, link: item.link,
						title: item.title.rendered,
						location : {lat: item.acf.location.lat, lng: item.acf.location.lng},
						img : item._embedded["wp:featuredmedia"][0].source_url,
						text : item.content.rendered,
						kind : item.acf.kind,
						thumbnail : item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url
					}
				});
				this.setState(newState);
				this.props.onLoadCompleted();
			}
		}

		this.watchId = navigator.geolocation.watchPosition(
			this.onPositionUpdated,
			function(e) { console.log(e.message); },
			{"enableHighAccuracy": false, "timeout": 20000, "maximumAge": 2000}
		);	
	}

	filterByCategory(spot) {
		const cat = CATS[this.props.category];
		let info = undefined;
		if(!cat) {
			return info;
		}
		cat.forEach(tab => {
			if(!tab.kind) {
				if(!spot.kind) {
					info = tab;
				}
			} else if(tab.kind.includes(spot.kind)) {
				info = tab;
			}
		});
		return info;
	}

	listSpots() {
		const width = this.props.wide ? 50 : 100;
		const height = this.props.wide ? 50 : (width * window.innerHeight / window.innerWidth);

		return this.state.spots.map((spot) => {
			let tab = this.filterByCategory(spot);
			if(!tab) {
				return "";
			}			
			const pos = this.getRelativePosition(spot);

			let y = (pos.y - this.state.y) * this.state.scale * width + 0.5 * width;
			let x = (pos.x - this.state.x) * this.state.scale * width + 0.5 * width;	

			const selected = this.state.selected === spot.id || this.state.picked === spot.id;
			const picked = this.state.picked === spot.id;
			const remain = this.state.remain === spot.id;
			const touchable = 'ontouchstart' in window;

			if(y < 0 || y > height || x < 0 || x > width) {
				return null;
			}

			let img = process.env.PUBLIC_URL + "/images/mappin";
			if(tab.icon) {
				img += "_" + tab.icon;
			}
			img += selected ? "_selected" : "";
			img += ".svg";

			let icon = (
				<div className='spot-icon' key={spot.id} style={{
						position: 'absolute',
						top: y + 'vw',
						left: x + 'vw',
						pointerEvents : 'none',
						zIndex : selected ? 1000 : parseInt(y, 10),
						transform: 'translate(-50%, -50%)',
						overflowX: 'visible',
						whiteSpace: 'nowrap',
						textAlign: 'center'
					}}>
						<img src={img} alt={spot.title}
							onClick={() => this.onClicked(spot)} 
							onMouseOver={() => this.onMouseOver(spot)}
							style={{
								width: selected ? 40 : 20,
								height: selected ? 40 : 20,
								position: 'relative',
								top: selected ? 2 : 0,
								pointerEvents : 'visible'
							}}
						/>
						<p className={"spot-icon-tip " + (selected ? "spot-icon-tip-selected" : "")}  
							onClick={() => this.onClicked(spot)} 
							onMouseOver={() => this.onMouseOver(spot)}
							style={{
								position: 'relative',
								top: -50,
								pointerEvents : 'auto'
							}}
						>
							{selected ? spot.title : ""}
						</p>
				</div>
			);
			if(selected && !remain) {
				return (
					<Tooltip open={picked} arrow title={touchable ? "タップで詳細" : "クリックで詳細"} key={spot.id}>
						{icon}
					</Tooltip>
				);
			} else {
				return icon;
			}
		});
	}

	currentPos() {
		if(this.state.currentLat < 0 || this.state.currentLng < 0) {
			return null;
		}
		let spot = {location : {lat : this.state.currentLat, lng : this.state.currentLng}};
		let pos = this.getRelativePosition(spot);

		const width = this.props.wide ? 50 : 100;
		const height = width * window.innerHeight / (window.innerWidth / (this.props.wide ? 2 : 1));

		let y = (pos.y - this.state.y) * this.state.scale * width + 0.5 * width;
		let x = (pos.x - this.state.x) * this.state.scale * width + 0.5 * width;	

		if(y < 0 || y > height || x < 0 || x > width) {
			return null;
		}

		let img = process.env.PUBLIC_URL + "/images/mappin_here.svg";

		return (
			<div className='here-icon' style={{
				position: 'absolute',
				top: y + 'vw',
				left: x + 'vw',
				pointerEvents : 'none',
				transform: 'translate(-50%, -50%)',
				overflowX: 'visible',
				whiteSpace: 'nowrap',
				textAlign: 'center'
			}}>
				<img src={img} alt="現在位置"
					style={{
						width: 40,
						height: 40,
						position: 'relative',
						top: 2
					}}
				/>
			</div>
		);
	}

	onShowStatusChanged(inView, entry)
	{
		console.log("map is " + (inView ? "shown" : "gone"));
		this.setState({show: inView});
	}


	render() {
		const width = this.props.wide ? 50 : 100;
		const size = width * this.state.scale;
		let x = (-1.0 * (this.state.x + 0.5) * this.state.scale + 0.5) * width;
		let y = (-1.0 * (this.state.y + 0.5) * this.state.scale + 0.5) * width;

		const touchable = 'ontouchstart' in window;

		return (
			<>
				<InView as="div" onChange={this.onShowStatusChanged}>
					<div className='map' 
						style={{backgroundColor: '#EEEEEEEE',
							backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/takaze_map_green.svg)',
							backgroundRepeat: 'no-repeat',
							backgroundSize: size + 'vw',
							backgroundPositionX: x + 'vw',
							backgroundPositionY: y + 'vw',
							width: this.props.wide ? '50vw' : '100vw',
							height: (this.props.wide && !this.props.app) ? '50vw' : '100vh',
							position: 'relative',
							cursor: this.state.dragging ? 'all-scroll' : 'default'
						}}
						onPointerDown={this.onDragStarted}
						onPointerUp={this.onDragEnded}
						onPointerLeave={this.onDragEnded}
						onPointerMove={this.onDrag}
						onTouchStart={this.onTouchStart}
						onTouchMove={this.onTouchMove}
						onTouchEnd={this.onTouchEnd}
					>
						{this.props.app ? <ArrowBackIcon onClick={this.onReload} fontSize="large" color="primary" /> : ""}
						<HomeIcon onClick={this.onResetMap} className="zoom-icon" fontSize="large" color="primary" />
						<ZoomOutIcon onClick={this.onDownScale} className="zoom-icon" fontSize="large" color={this.state.scale === 1 ? "disabled" : "primary"} />
						<ZoomInIcon onClick={this.onUpScale} className="zoom-icon" fontSize="large" color={this.state.scale === 5 ? "disabled" : "primary"} />
						<Typography className="zoom-icon" style={{visibility: touchable ? "visible" : "hidden"}} variant="button" color="primary">指2本で操作</Typography>
						<PinchIcon className="zoom-icon" style={{visibility: touchable ? "visible" : "hidden"}} fontSize="large" color="primary"/>
						{this.listSpots()}
						{this.currentPos()}
					</div>
				</InView>
				{this.props.wide ?
					<SpotListPane spots={this.state.spots} onSelected={this.onSelectedByList} app={this.props.app} category={this.props.category} /> :
					this.state.show ? <SpotList spots={this.state.spots} onSelected={this.onSelectedByList} app={this.props.app} category={this.props.category} /> : ""
				}
			</>
		);
	}
}

export default Map;
