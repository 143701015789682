const CATS = {
	eat : [
		{
			kind : ["飲食"],
			label : "食べる",
			icon : ""
		},
	],
	joy : [
		{
			kind : ["エンタメ","果樹園"],
			label : "遊ぶ",
			icon : ""
		},
	],
	spot : [
		{
			kind : ["仏像","神像","地蔵"],
			label : "仏像・神像",
			icon : "relief"
		},
		{
			kind : ["池","湖"],
			label : "池",
			icon : "pond"
		},
		{
			kind : null,
			label : "名所",
			icon : ""
		},
	],
}

export default CATS;